import type { AuthContextType } from '@context/auth.context';
import type { TelegramUser } from '@interfaces/telegram-user';
import { actionRequest } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  Update = 'update',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.Update: {
        const { id: userId, ...rest } = data;
        return await actionRequest<TelegramUser>(auth, request, `manage/telegram-user/${userId}`, rest);
      }
      default:
        throw new Error(`Undefined intent '${intent}' for the action`);
    }
  }) satisfies ActionFunction;
