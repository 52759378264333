import type { CustomPasswordInputProps } from '@components/PasswordInputWithStrengthMeter';
import PasswordInputWithStrengthMeter from '@components/PasswordInputWithStrengthMeter';
import type { FormRule, InputProps } from 'antd';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import PhoneInput from 'antd-phone-input';
import type { PhoneNumber } from 'antd-phone-input/types';
import type { FC, HTMLAttributes, MouseEvent, ReactNode } from 'react';
import { checkValidity, getFormattedNumber, parsePhoneNumber } from 'react-phone-hooks';

export interface EditableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  placeholder?: string;
  inputType: 'text' | 'password' | 'number' | 'select' | 'switch' | 'textarea' | 'phone-number';
  required?: boolean;
  disabled?: boolean;
  max?: number;
  min?: number;
  autoComplete?: string;
  customRules?: FormRule[];
  selectOptions?: DefaultOptionType[];
  customPasswordInputProps?: CustomPasswordInputProps;
  children: ReactNode;
  inputProps?: InputProps;
}

const EditableCell: FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  placeholder,
  inputType,
  required = true,
  disabled = false,
  max,
  min,
  autoComplete,
  customRules,
  selectOptions,
  customPasswordInputProps,
  children,
  inputProps,
  ...restProps
}) => {
  const onClickHandler = (event: MouseEvent<HTMLElement>) => event.stopPropagation();

  const inputNode =
    inputType === 'number' ? (
      <InputNumber onClick={onClickHandler} disabled={disabled} placeholder={placeholder} autoComplete={autoComplete} />
    ) : inputType === 'select' ? (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label && typeof option.label === 'string' ? option.label : '')
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        options={selectOptions}
        onClick={onClickHandler}
        disabled={disabled}
        placeholder={placeholder}
      />
    ) : inputType === 'switch' ? (
      <Switch onClick={(_, event) => event.stopPropagation()} disabled={disabled} />
    ) : inputType === 'textarea' ? (
      <Input.TextArea
        rows={4}
        disabled={disabled}
        count={{ show: !!max, max: max }}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    ) : inputType === 'phone-number' ? (
      <PhoneInput
        enableSearch
        disabled={disabled}
        count={{ show: !!max, max: max }}
        placeholder={placeholder}
        country="us"
        allowClear={true}
        autoComplete={autoComplete}
      />
    ) : (
      <Input
        onClick={onClickHandler}
        disabled={disabled}
        count={{ show: !!max, max: max }}
        placeholder={placeholder}
        autoComplete={autoComplete}
        {...inputProps}
      />
    );

  const phoneValidator: FormRule = () => ({
    validator: (_, number: string | null) => {
      if (number === null) {
        return Promise.resolve();
      }
      const phoneNumber = parsePhoneNumber(getFormattedNumber(number.replace(/^\+/, '')));
      return (phoneNumber.countryCode === null && phoneNumber.areaCode === null && phoneNumber.phoneNumber === null) ||
        checkValidity(phoneNumber)
        ? Promise.resolve()
        : Promise.reject('Invalid phone number format.');
    },
  });

  const formItemRules: FormRule[] = [
    ...(required
      ? [{ required: true, message: `Please enter a ${title ? title.toLowerCase() : 'value'}.` }]
      : [{ required: false }]),
    ...(max !== undefined ? [{ max: max, message: `${title} can not exceed ${max} characters.` }] : []),
    ...(min !== undefined ? [{ min: min, message: `${title} can not be less than ${min} characters.` }] : []),
    ...(inputType === 'phone-number' ? [phoneValidator] : []),
    ...(customRules && customRules.length > 0 ? customRules : []),
  ];

  return (
    <td {...restProps}>
      {editing ? (
        inputType === 'password' ? (
          <PasswordInputWithStrengthMeter
            name={dataIndex}
            rules={formItemRules}
            style={{ margin: 0 }}
            inputProps={{
              ...(customPasswordInputProps ?? {}),
              onClick: onClickHandler,
              disabled: disabled,
              count: { show: !!max, max: max },
              placeholder: placeholder,
            }}
          />
        ) : (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={formItemRules}
            valuePropName={inputType === 'switch' ? 'checked' : undefined}
            normalize={(value): unknown => {
              return inputType === 'phone-number'
                ? value.countryCode !== null
                  ? `+${(value as PhoneNumber).countryCode || ''}${(value as PhoneNumber).areaCode || ''}${(value as PhoneNumber).phoneNumber || ''}`
                  : null
                : value;
            }}
          >
            {inputNode}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
