import type { AuthContextType } from '@context/auth.context';
import type { MessageTemplate, MessageTemplateTranslation } from '@interfaces/message-template';
import { actionRequest } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  AddTemplate = 'add-template',
  UpdateTemplate = 'update-template',
  DeleteTemplate = 'delete-template',
  AddTemplateTranslation = 'add-template-translation',
  UpdateTemplateTranslation = 'update-translation',
  DeleteTranslation = 'delete-translation',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.AddTemplate:
        return await actionRequest<MessageTemplate>(auth, request, 'manage/message-template', data);
      case ACTIONS.UpdateTemplate:
      case ACTIONS.DeleteTemplate: {
        const { id: templateId, ...rest } = data;
        return await actionRequest<MessageTemplate>(
          auth,
          request,
          `manage/message-template/${templateId}`,
          intent === ACTIONS.UpdateTemplate ? rest : undefined,
        );
      }
      case ACTIONS.AddTemplateTranslation: {
        const { template_id, ...rest } = data;
        return await actionRequest<MessageTemplateTranslation>(
          auth,
          request,
          `manage/message-template/${template_id}/translation`,
          rest,
        );
      }
      case ACTIONS.UpdateTemplateTranslation: {
        const { template_id, language_code_previous, ...rest } = data;
        return await actionRequest<MessageTemplate>(
          auth,
          request,
          `manage/message-template/${template_id}/translation/${language_code_previous}`,
          rest,
        );
      }
      case ACTIONS.DeleteTranslation: {
        const { template_id, language_code } = data;
        return await actionRequest<MessageTemplate>(
          auth,
          request,
          `manage/message-template/${template_id}/translation/${language_code}`,
        );
      }
      default:
        throw new Error('Undefined intent for the action');
    }
  }) satisfies ActionFunction;
