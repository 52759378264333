import { MenuItemType } from '@interfaces/menu-item-type';
import type { TelegramMenuItem } from '@interfaces/telegram-menu-item';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import { ACTIONS } from '@pages/Management/MenuManagement/action';
import { Alert, Button, Form, Input, Modal, Select, Switch } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface MenuItemWithDefaultTranslation extends Record<string, unknown> {
  id: number;
  frontend_label: string;
  is_autonomous: boolean;
  has_subkeyboard: boolean;
  type: 'button' | 'command';
  command: string | null;
  label: string;
  answer: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (menuItem: Omit<TelegramMenuItem, 'translations'>) => void;
  onCancel: () => void;
}

const MenuItemAddForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
    resetFetcher,
  } = useForm<Omit<TelegramMenuItem, 'translations'>, MenuItemWithDefaultTranslation>(
    'menu-item-add-form',
    true,
    (response) => response && onCreate(response),
  );
  const itemType = Form.useWatch<MenuItemWithDefaultTranslation['type']>('type', form);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    resetFetcher();
    onCancel();
  }, [form, resetFetcher, onCancel]);

  return (
    <Modal
      open={open}
      title="Add menu item"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" disabled={isLoadingOrSubmitting} onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingOrSubmitting} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => submitForm(ACTIONS.AddMenuItem, 'POST')}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={isLoadingOrSubmitting}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="label"
          label="Label (English)"
          rules={[
            {
              required: true,
              message: 'Please enter a label.',
            },
            {
              max: 64,
              message: 'Label can not exceed 64 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 64 }} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select a type.',
            },
          ]}
          initialValue="button"
        >
          <Select
            options={Object.entries(MenuItemType).map(([key, value]) => ({
              label: key,
              value,
            }))}
          />
        </Form.Item>
        {itemType === 'command' && (
          <Form.Item
            name="command"
            label="Command"
            rules={[
              {
                required: itemType === 'command',
                message: 'Please enter a command.',
              },
              {
                max: 32,
                message: 'The command can not exceed 32 characters.',
              },
              {
                pattern: /^[a-z0-9_]+$/,
                message: 'The command can only contain lowercase English letters, digits and underscores.',
              },
            ]}
          >
            <Input count={{ show: true, max: 32 }} />
          </Form.Item>
        )}
        <Form.Item name="is_autonomous" label="Autonomous" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="has_subkeyboard" label="Subkeyboard" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="answer" label="Telegram message (English)">
          <Input />
        </Form.Item>
        <Form.Item name="frontend_label" label="Web-client message (English)">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MenuItemAddForm;
