import { UserOutlined } from '@ant-design/icons';
import ThemeSwitcher from '@components/ThemeSwitcher';
import useAuth from '@hooks/use-auth';
import { Role } from '@interfaces/role';
import { Avatar, Layout, Menu } from 'antd';
import type { ItemType } from 'antd/es/menu/interface';
import type { FC } from 'react';
import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';

import styles from './AuthenticatedLayout.module.scss';

const AuthenticatedLayout: FC = () => {
  const { isAuthenticated, signOut, hasRole } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ returnUrl: location }} replace />;
  }

  return (
    <Layout className={styles.wrapper}>
      <Layout.Header className={styles.header}>
        <Menu
          mode="horizontal"
          selectedKeys={['conversations', 'management'].filter((key) => location.pathname.startsWith(`/${key}`))}
          onClick={(info) => {
            if (info.key === 'logout') {
              signOut();
            }
          }}
          items={[
            {
              label: (
                <NavLink to="/conversations" viewTransition>
                  Conversations
                </NavLink>
              ),
              key: 'conversations',
            },
            ...(hasRole(Role.Admin)
              ? [
                  {
                    label: (
                      <NavLink to="/management" viewTransition>
                        Management
                      </NavLink>
                    ),
                    key: 'management',
                  } as ItemType,
                ]
              : []),
            {
              label: <ThemeSwitcher />,
              key: 'theme-switcher',
              style: { marginLeft: 'auto' },
              className: styles.withoutHoverEffect,
            },
            {
              label: <Avatar icon={<UserOutlined />} />,
              key: 'account',
              children: [
                {
                  label: (
                    <NavLink to="/user/messages" viewTransition>
                      My Messages
                    </NavLink>
                  ),
                  key: 'own-messages',
                },
                {
                  label: 'Logout',
                  key: 'logout',
                },
              ],
            },
          ]}
        />
      </Layout.Header>
      <Layout className={styles.siteContent}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
