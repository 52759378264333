import { LockOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import calculatePasswordStrength from '@helpers/calculate-password-strength';
import useAuth from '@hooks/use-auth';
import type { InputRef } from 'antd';
import { Alert, Button, Form, Input, message, Typography } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFetcher, useLocation, useNavigate } from 'react-router-dom';

import styles from './Login.module.scss';

interface LoginFormValues {
  username: string;
  password: string;
}

const Login: FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const { isAuthenticated } = useAuth();

  const [error, setError] = useState<string | null>(null);
  const [form] = Form.useForm<LoginFormValues>();
  const { submit, state, data: response } = useFetcher<Response>();

  const usernameInputRef = useRef<InputRef>(null);
  const usernameInput = usernameInputRef.current;

  const onSubmit = (values: LoginFormValues) => {
    submit(
      Object.entries(values).reduce((formData, [key, value]) => {
        formData.append(key, value);

        return formData;
      }, new FormData()),
      {
        method: 'post',
        encType: 'application/x-www-form-urlencoded',
      },
    );
  };

  const redirectToHome = useCallback(
    () => navigate(locationState?.returnUrl?.pathname || '/', { replace: true }),
    [navigate, locationState],
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const currentPassword = form.getFieldValue('password') as string;
    const passwordStrength = currentPassword ? calculatePasswordStrength(currentPassword) : null;
    if (passwordStrength?.id !== 3) {
      messageApi
        .warning({
          content: 'Please change your password to comply with our password policy. You will be redirected shortly.',
          duration: 5,
        })
        .then(redirectToHome);
    } else {
      redirectToHome();
    }
  }, [isAuthenticated, form, messageApi, redirectToHome]);

  useEffect(() => {
    if (state === 'submitting' && error !== null) {
      setError(null);
      return;
    }

    if (response !== undefined && response.status === 401) {
      setError('Username or password is incorrect.');
    }
  }, [state, response, error]);

  useEffect(() => {
    usernameInputRef.current?.focus();
  }, [usernameInput]);

  return (
    <Form className={styles.loginForm} form={form} onFinish={onSubmit} layout="vertical">
      <div className={styles.header}>
        <MessageOutlined className={styles.appIcon} />
        <Typography.Title level={2}>Sign In</Typography.Title>
      </div>
      {contextHolder}
      {error !== null ? <Alert className={styles.formError} message={error} type="error" /> : null}
      <Form.Item name="username" rules={[{ required: true, message: 'Please enter your username.' }]}>
        <Input
          ref={usernameInputRef}
          prefix={<UserOutlined />}
          placeholder="Username"
          autoComplete="username"
          disabled={state === 'submitting'}
        />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password.' }]}>
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          disabled={state === 'submitting'}
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            disabled={
              state === 'submitting' ||
              !form.isFieldsTouched(true) ||
              form.getFieldsError().some(({ errors }) => errors.length > 0)
            }
            loading={state === 'submitting'}
          >
            Log in
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default Login;
