import type { AuthContextType } from '@context/auth.context';
import type { TelegramGroup } from '@interfaces/telegram-group';
import { actionRequest } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  Authorize = 'authorize',
  Update = 'update',
  SyncName = 'sync_name',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.Authorize:
        return await actionRequest<TelegramGroup>(auth, request, 'manage/telegram-group', data);
      case ACTIONS.Update: {
        const { id, ...rest } = data;
        return await actionRequest<TelegramGroup>(auth, request, `manage/telegram-group/${id}`, rest);
      }
      case ACTIONS.SyncName: {
        const { id } = data;
        return await actionRequest<TelegramGroup>(auth, request, `manage/telegram-group/${id}/sync`);
      }
      default:
        throw new Error(`Undefined intent '${intent}' for the action`);
    }
  }) satisfies ActionFunction;
