import { EditOutlined, SaveOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import type { ColumnsType, PaginatedFilteredTableRef } from '@components/PaginatedFilteredTable';
import PaginatedFilteredTable from '@components/PaginatedFilteredTable';
import { languages } from '@interfaces/language';
import type { TelegramGroup } from '@interfaces/telegram-group';
import { ACTIONS } from '@pages/Management/ConversationManagement/action';
import BulkMessageForm from '@pages/Management/ConversationManagement/BulkMessageForm';
import useEditableForm from '@pages/Management/hooks/use-editable-form';
import type { LoaderData } from '@services/types/loader-data';
import { Alert, Badge, Button, Form, message, Space, Tooltip } from 'antd';
import type { ParsedQs } from 'qs';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import EditableCell from '../EditableCell';
import type { EditableCellProps } from '../EditableCell/EditableCell';
import styles from './ConversationManagement.module.scss';
import CreateConversationForm from './CreateConversationForm';
import type { loader } from './loader';

interface ConversationForm extends Record<string, unknown> {
  name: string;
  language_code: string;
  is_authorized: boolean;
}

const ConversationManagement: FC = () => {
  const loaderData = useLoaderData() as LoaderData<typeof loader>;
  const [data, setData] = useState(loaderData);
  useEffect(() => {
    setData(loaderData);
  }, [loaderData]);

  const [isCreateConversationModalOpen, setCreateConversationModalOpen] = useState(false);
  const [isBulkMessageModalOpen, setBulkMessageModalOpen] = useState(false);

  const tableRef = useRef<PaginatedFilteredTableRef>(null);
  const [appliedFilters, setAppliedFilters] = useState<ParsedQs>();

  const {
    form,
    isLoadingOrSubmitting,
    isRecordEdited,
    cancelEditing,
    setRecordEditable,
    submitForm,
    error: formError,
    resetFetcher,
  } = useEditableForm<TelegramGroup, ConversationForm>(
    'id',
    ['name', 'is_authorized', 'language_code'],
    'conversation-fetcher',
    true,
  );

  const [messageApi, contextHolder] = message.useMessage();

  const columns: ColumnsType<TelegramGroup> = [
    {
      key: 'id',
      title: 'Telegram group ID',
      dataIndex: 'id',
    },
    {
      key: 'name',
      title: 'Conversation name',
      dataIndex: 'name',
      filterable: true,
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'name',
        inputType: 'text',
        title: 'Name',
        editing: isRecordEdited(record),
        max: 96,
      }),
    },
    {
      key: 'language_code',
      title: 'Language',
      dataIndex: 'language_code',
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'language_code',
        inputType: 'select',
        selectOptions: Object.entries(languages).map(([key, value]) => ({
          value: key,
          label: value,
        })),
        title: 'Language',
        editing: isRecordEdited(record),
      }),
      render: (value: string) => {
        return value in languages ? languages[value] : value;
      },
    },
    {
      key: 'is_authorized',
      title: 'Authorized',
      dataIndex: 'is_authorized',
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'is_authorized',
        inputType: 'switch',
        title: 'Autonomous',
        editing: isRecordEdited(record),
        required: false,
      }),
      render: (_, record) => (
        <Badge status={record.is_authorized ? 'success' : 'error'} text={record.is_authorized ? 'Yes' : 'No'} />
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (_, record) => {
        const editable = isRecordEdited(record);
        return (
          <Space size="middle">
            {editable ? (
              <>
                <Button type="primary" icon={<SaveOutlined />} title="Save" onClick={() => submitForm(record)} />
                <Button
                  type="default"
                  icon={<StopOutlined />}
                  title="Cancel"
                  onClick={() => {
                    resetFetcher();
                    cancelEditing();
                  }}
                />
              </>
            ) : (
              <>
                <Button type="default" icon={<EditOutlined />} title="Edit" onClick={() => setRecordEditable(record)} />
                <Button
                  type="default"
                  disabled={!record.is_authorized}
                  icon={<SyncOutlined />}
                  title="Sync the conversation name to match the Telegram group name"
                  onClick={() => submitForm({ id: record.id }, ACTIONS.SyncName, undefined, 'POST')}
                />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.buttons}>
        <Tooltip title="Send bulk message to all conversations. Use the conversation filter function to message a specific subset.">
          <Button
            type="default"
            onClick={() => {
              setBulkMessageModalOpen(true);
            }}
            disabled={isLoadingOrSubmitting}
          >
            Bulk Message
          </Button>
        </Tooltip>
        <Button
          type="primary"
          onClick={() => {
            setCreateConversationModalOpen(true);
          }}
          disabled={isLoadingOrSubmitting}
        >
          Create Conversation
        </Button>
      </div>
      <BulkMessageForm
        open={isBulkMessageModalOpen}
        onCancel={() => {
          setBulkMessageModalOpen(false);
        }}
        onSent={() => {
          setBulkMessageModalOpen(false);
          // noinspection JSIgnoredPromiseFromCall
          messageApi.info('Sending message queued.');
        }}
        filters={appliedFilters}
        isFormDisabled={data.data.length === 0}
      />
      <CreateConversationForm
        open={isCreateConversationModalOpen}
        onCreate={() => setCreateConversationModalOpen(false)}
        onCancel={() => setCreateConversationModalOpen(false)}
      />
      <Form form={form} component={false}>
        {formError && (
          <Alert
            message={formError}
            closable
            onClose={() => resetFetcher()}
            style={{ margin: '24px 0' }}
            type="error"
          />
        )}
        <PaginatedFilteredTable<TelegramGroup>
          ref={tableRef}
          data={data}
          columns={columns}
          rowKey="id"
          loading={isLoadingOrSubmitting}
          rowClassName="editable-row"
          components={{ body: { cell: EditableCell } }}
          onFiltersChange={(filters) => setAppliedFilters(filters)}
        />
      </Form>
      {contextHolder}
    </>
  );
};

export default ConversationManagement;
