import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlusOutlined,
  SaveOutlined,
  StopOutlined,
} from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { languages } from '@interfaces/language';
import { MenuItemType } from '@interfaces/menu-item-type';
import type { TelegramMenuItem } from '@interfaces/telegram-menu-item';
import type { TelegramMenuItemTranslation } from '@interfaces/telegram-menu-item-translation';
import useEditableForm from '@pages/Management/hooks/use-editable-form';
import { ACTIONS } from '@pages/Management/MenuManagement/action';
import withStopPropagation from '@pages/Management/utils/ui/with-stop-propagation';
import type { LoaderData } from '@services/types/loader-data';
import { Badge, Button, Form, Popconfirm, Space, Table } from 'antd';
import type { ExpandedRowRender } from 'rc-table/lib/interface';
import type { CSSProperties, FC, HTMLAttributes, Key } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import EditableCell from '../EditableCell';
import type { EditableCellProps } from '../EditableCell/EditableCell';
import type { loader } from './loader';
import MenuItemAddForm from './MenuItemAddForm';
import MenuItemAddTranslationForm from './MenuItemAddTranslationForm';
import styles from './MenuItemManagement.module.scss';

interface RawContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RawContext = createContext<RawContextProps>({});

const DragHandle: FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RawContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: FC<RowProps> = (props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RawContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RawContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RawContext.Provider>
  );
};

interface MenuItemForm extends Record<string, unknown> {
  frontend_label: string;
  is_autonomous: boolean;
  has_subkeyboard: boolean;
  type: 'button' | 'command';
  command: string | null;
}

interface MenuItemTranslationForm extends Record<string, unknown> {
  label: string;
  answer: string;
  language_code: string;
}

const MenuItemManagement: React.FC = () => {
  const fetchedMenuItems = useLoaderData() as LoaderData<typeof loader>;
  const [menuItems, setMenuItems] = useState<TelegramMenuItem[]>();
  useEffect(() => {
    setMenuItems(fetchedMenuItems);
  }, [fetchedMenuItems]);

  const {
    form: menuItemForm,
    isLoadingOrSubmitting: isLoadingOrSubmittingMenuItem,
    isRecordEdited: isMenuItemEdited,
    cancelEditing: cancelMenuItemEditing,
    setRecordEditable: setMenuItemEditable,
    submitForm: submitMenuItemForm,
    submitFunction,
    resetFetcher: resetMenuItemFetcher,
  } = useEditableForm<TelegramMenuItem, MenuItemForm>(
    'id',
    ['type', 'command', 'frontend_label', 'is_autonomous', 'has_subkeyboard'],
    'menu-item-fetcher',
    true,
  );
  const {
    form: menuItemTranslationForm,
    isLoadingOrSubmitting: isLoadingOrSubmittingMenuItemTranslation,
    isRecordEdited: isMenuItemTranslationEdited,
    cancelEditing: cancelMenuItemTranslationEditing,
    setRecordEditable: setMenuItemTranslationEditable,
    submitForm: submitMenuItemTranslationForm,
    resetFetcher: resetMenuItemTranslationFetcher,
  } = useEditableForm<TelegramMenuItemTranslation, MenuItemTranslationForm>(
    ['item_id', 'language_code'],
    ['label', 'answer', 'language_code'],
    'menu-item-translation-fetcher',
    true,
  );
  const cellItemType = Form.useWatch<MenuItemForm['type']>('type', menuItemForm);

  useEffect(() => {
    if (cellItemType === 'button') {
      menuItemForm.setFieldsValue({
        command: '',
      });
    }
  }, [cellItemType, menuItemForm]);

  const isLoadingOrSubmitting = isLoadingOrSubmittingMenuItem || isLoadingOrSubmittingMenuItemTranslation;

  const [isMenuItemAddModalOpen, setMenuItemAddModalOpen] = useState(false);
  const [isMenuItemTranslationAddModalOpen, setMenuItemTranslationAddModalOpen] = useState(false);
  const [addMenuItemTranslationId, setAddMenuItemTranslationId] = useState(0);
  const [addMenuItemTranslationLanguages, setAddMenuItemTranslationLanguages] = useState(languages);
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);

  const onTriggerCreateMenuItem = useCallback((menuItem: Omit<TelegramMenuItem, 'translations'>) => {
    setExpandedRowKeys([menuItem.id as number]);
    setMenuItemAddModalOpen(false);
  }, []);

  const onTriggerCreateMenuItemTranslation = useCallback((translation: TelegramMenuItemTranslation) => {
    setExpandedRowKeys([translation.item_id]);
    setMenuItemTranslationAddModalOpen(false);
  }, []);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over !== null && active.id !== over.id) {
      setMenuItems((prevState) => {
        if (!prevState) {
          return prevState;
        }
        const activeIndex = prevState?.findIndex((record) => record.id === active.id);
        const overIndex = prevState?.findIndex((record) => record.id === over.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      submitFunction(
        {
          intent: ACTIONS.SwapOrder,
          src_id: active.id,
          dst_id: over?.id,
        },
        {
          method: 'POST',
          encType: 'application/json',
        },
      );
    }
  };

  const expandedRowRender: ExpandedRowRender<TelegramMenuItem> = (record) => {
    const translations = record.translations;
    const existingLanguages = translations.map((translation) => translation.language_code);

    return (
      <Form form={menuItemTranslationForm} component={false}>
        <Table<TelegramMenuItemTranslation>
          dataSource={translations}
          rowKey={(record) => `${record.item_id}_${record.language_code}`}
          pagination={false}
          rowClassName="editable-row"
          loading={isLoadingOrSubmitting}
          components={{
            body: { cell: EditableCell },
          }}
        >
          <Table.Column<TelegramMenuItemTranslation>
            key="label"
            dataIndex="label"
            title="Label"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'label',
              inputType: 'text',
              title: 'Label',
              editing: isMenuItemTranslationEdited(record),
              max: 64,
            })}
          />
          <Table.Column<TelegramMenuItemTranslation>
            key="answer"
            dataIndex="answer"
            title="Telegram message"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'answer',
              inputType: 'text',
              title: 'Telegram message',
              editing: isMenuItemTranslationEdited(record),
              required: false,
            })}
          />
          <Table.Column<TelegramMenuItemTranslation>
            key="language_code"
            dataIndex="language_code"
            title="Language"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'language_code',
              inputType: 'select',
              selectOptions: Object.entries(languages)
                .filter(([key]) => key === record.language_code || !existingLanguages.includes(key))
                .map(([key, value]) => ({
                  value: key,
                  label: value,
                })),
              title: 'Language',
              editing: record.language_code !== 'en' && isMenuItemTranslationEdited(record),
            })}
            render={(value: string) => {
              return value in languages ? languages[value] : value;
            }}
          />
          <Table.Column<TelegramMenuItemTranslation>
            key="actions"
            title="Actions"
            render={(_, record) => {
              const editable = isMenuItemTranslationEdited(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        title="Save"
                        onClick={() =>
                          submitMenuItemTranslationForm(record, ACTIONS.UpdateMenuItemTranslation, {
                            language_code_previous: record.language_code,
                          })
                        }
                      />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={() => {
                          resetMenuItemTranslationFetcher();
                          cancelMenuItemTranslationEditing();
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setMenuItemTranslationEditable(record);
                        }}
                      />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => {
                          // noinspection JSIgnoredPromiseFromCall
                          submitMenuItemTranslationForm(
                            {
                              item_id: record.item_id,
                              language_code: record.language_code,
                            },
                            ACTIONS.DeleteMenuItemTranslation,
                            undefined,
                            'DELETE',
                          );
                        }}
                        disabled={record.language_code === 'en'}
                      >
                        <Button danger icon={<DeleteOutlined />} disabled={record.language_code === 'en'} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    );
  };

  return (
    <>
      <MenuItemAddForm
        open={isMenuItemAddModalOpen}
        onCreate={onTriggerCreateMenuItem}
        onCancel={() => {
          setMenuItemAddModalOpen(false);
        }}
      />
      <MenuItemAddTranslationForm
        open={isMenuItemTranslationAddModalOpen}
        onCreate={onTriggerCreateMenuItemTranslation}
        onCancel={() => {
          setMenuItemTranslationAddModalOpen(false);
        }}
        item_id={addMenuItemTranslationId}
        languages={addMenuItemTranslationLanguages}
      />
      <div className={styles.menuItems}>
        <Button
          onClick={() => {
            submitFunction(
              {
                intent: ACTIONS.Notify,
              },
              {
                method: 'POST',
                encType: 'application/json',
              },
            );
          }}
          disabled={isLoadingOrSubmitting}
        >
          Push to Telegram
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setMenuItemAddModalOpen(true);
          }}
          disabled={isLoadingOrSubmitting}
        >
          Add Menu Item
        </Button>
      </div>
      <Form form={menuItemForm} component={false}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={(menuItems || []).map((item) => item.id)} strategy={verticalListSortingStrategy}>
            <Table<TelegramMenuItem>
              dataSource={menuItems}
              rowKey="id"
              loading={isLoadingOrSubmitting}
              rowClassName="editable-row"
              components={{
                body: { row: Row, cell: EditableCell },
              }}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: [],
                expandedRowKeys,
                onExpandedRowsChange: (expandedKeys) => {
                  setExpandedRowKeys(expandedKeys);
                },
                expandRowByClick: true,
              }}
              pagination={false}
            >
              <Table.Column<TelegramMenuItem>
                key="sort_order"
                title="Order"
                align="center"
                width="80"
                render={() => <DragHandle />}
              />
              <Table.Column<TelegramMenuItem> key="id" title="ID" dataIndex="id" />
              <Table.Column<TelegramMenuItem>
                key="type"
                title="Type"
                dataIndex="type"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'type',
                  inputType: 'select',
                  title: 'Type',
                  editing: isMenuItemEdited(record),
                  required: true,
                  selectOptions: Object.entries(MenuItemType).map(([key, value]) => ({
                    label: key,
                    value,
                  })),
                })}
                render={(value: MenuItemType) => (value === MenuItemType.Button ? 'Button' : 'Command')}
              />
              <Table.Column<TelegramMenuItem>
                key="command"
                title="Command"
                dataIndex="command"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'command',
                  inputType: 'text',
                  title: 'Command',
                  editing: isMenuItemEdited(record),
                  required: cellItemType === 'command',
                  disabled: cellItemType !== 'command',
                  max: 32,
                  customRules: [
                    {
                      pattern: /^[a-z0-9_]+$/,
                      message: 'The command can only contain lowercase English letters, digits and underscores.',
                    },
                  ],
                })}
              />
              <Table.Column<TelegramMenuItem>
                key="frontend_label"
                title="Web-client message"
                dataIndex="frontend_label"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'frontend_label',
                  inputType: 'text',
                  title: 'Web-client message',
                  editing: isMenuItemEdited(record),
                  required: false,
                })}
              />
              <Table.Column<TelegramMenuItem>
                key="is_autonomous"
                title="Autonomous"
                dataIndex="is_autonomous"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'is_autonomous',
                  inputType: 'switch',
                  title: 'Autonomous',
                  editing: isMenuItemEdited(record),
                  required: false,
                })}
                render={(_, record) => (
                  <Badge
                    status={record.is_autonomous ? 'success' : 'error'}
                    text={record.is_autonomous ? 'Yes' : 'No'}
                  />
                )}
              />
              <Table.Column<TelegramMenuItem>
                key="has_subkeyboard"
                title="Subkeyboard"
                dataIndex="has_subkeyboard"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'has_subkeyboard',
                  inputType: 'switch',
                  title: 'Subkeyboard',
                  editing: isMenuItemEdited(record),
                  required: false,
                })}
                render={(_, record) => (
                  <Badge
                    status={record.has_subkeyboard ? 'success' : 'error'}
                    text={record.has_subkeyboard ? 'Yes' : 'No'}
                  />
                )}
              />
              <Table.Column<TelegramMenuItem>
                key="actions"
                title="Actions"
                render={(_, record) => {
                  const editable = isMenuItemEdited(record);
                  return (
                    <Space size="middle">
                      {editable ? (
                        <>
                          <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            title="Save"
                            onClick={withStopPropagation(() => submitMenuItemForm(record, ACTIONS.UpdateMenuItem))}
                          />
                          <Button
                            type="default"
                            icon={<StopOutlined />}
                            title="Cancel"
                            onClick={withStopPropagation(() => {
                              resetMenuItemFetcher();
                              cancelMenuItemEditing();
                            })}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            title="Add Translation"
                            onClick={withStopPropagation(() => {
                              setAddMenuItemTranslationId(record.id);
                              setAddMenuItemTranslationLanguages(
                                Object.fromEntries(
                                  Object.entries(languages).filter(
                                    ([key]) =>
                                      !record.translations
                                        .map((translation) => translation.language_code)
                                        .includes(key),
                                  ),
                                ),
                              );
                              setMenuItemTranslationAddModalOpen(true);
                            })}
                          />
                          <Button
                            type="default"
                            icon={<EditOutlined />}
                            title="Edit"
                            onClick={withStopPropagation(() => setMenuItemEditable(record))}
                          />
                          <Popconfirm
                            title="Sure to delete menu item with all of it's translations?"
                            onConfirm={withStopPropagation(() =>
                              submitMenuItemForm({ id: record.id }, ACTIONS.DeleteMenuItem, undefined, 'DELETE'),
                            )}
                            onCancel={withStopPropagation()}
                            onPopupClick={withStopPropagation()}
                          >
                            <Button danger title="Delete" icon={<DeleteOutlined />} onClick={withStopPropagation()} />
                          </Popconfirm>
                        </>
                      )}
                    </Space>
                  );
                }}
              />
            </Table>
          </SortableContext>
        </DndContext>
      </Form>
    </>
  );
};

export default MenuItemManagement;
