import useDocumentTitle from '@hooks/use-document-title';
import { ConfigProvider, Layout, Menu } from 'antd';
import type { FC } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import styles from './Management.module.scss';

interface MenuItem {
  to: string;
  label: string;
}

/**
 * Defines the main navigation menu items for the Management interface.
 * Each item represents a route in the management section.
 */
const MANAGEMENT_MENU_ITEMS: MenuItem[] = [
  { to: 'conversations', label: 'Conversations' },
  { to: 'telegram-menu', label: 'Telegram Menu' },
  { to: 'telegram-users', label: 'Telegram Users' },
  { to: 'message-templates', label: 'Message Templates' },
  { to: 'users', label: 'Users' },
  { to: 'settings', label: 'Settings' },
] as const;

const Management: FC = () => {
  useDocumentTitle('Management');
  const path = '/management';
  const location = useLocation();

  return (
    <Layout>
      <Layout.Sider className={styles.sider}>
        <Menu
          mode="inline"
          style={{ height: '100%' }}
          selectedKeys={[location.pathname.substring(location.pathname.lastIndexOf(path) + path.length + 1)]}
          items={MANAGEMENT_MENU_ITEMS.map(({ to, label }) => ({
            label: (
              <NavLink to={to} viewTransition>
                {label}
              </NavLink>
            ),
            key: to,
          }))}
        />
      </Layout.Sider>
      <Layout.Content className={styles.content}>
        <ConfigProvider theme={{ token: { zIndexPopupBase: 9999 } }}>
          <Outlet />
        </ConfigProvider>
      </Layout.Content>
    </Layout>
  );
};

export default Management;
