import type { AuthContextType } from '@context/auth.context';
import { apiRequest, isResponse } from '@services/fetcher';
import { ConflictError } from '@services/types/conflict-error';
import { ValidationError } from '@services/types/validation-error';
import type { V7_FormMethod } from 'react-router-dom';

interface ActionResponse {
  ok: boolean;
  status: number;
  statusText: string;
  details?: unknown;
}

export const actionRequest = async <T>(
  auth: AuthContextType,
  request: Request,
  url: string,
  data?: unknown,
): Promise<T | ActionResponse> => {
  const baseUrl = `${import.meta.env.VITE_API_URL}`;
  const method = request.method.toUpperCase() as V7_FormMethod;
  const options: RequestInit = {
    method: method,
    signal: request.signal,
    headers: request.headers,
  };

  // let response: Error | Response | T;

  if (method !== 'DELETE' && data !== undefined) {
    // We store action in intent, which needs to be removed before sending the request to the backend
    if (data && typeof data === 'object' && 'intent' in data) {
      delete data.intent;
    }
    options.body = JSON.stringify(data);
  }

  const response = await apiRequest<T>(`${baseUrl}/${url}`, auth, options);

  if (response instanceof ValidationError) {
    return {
      ok: false,
      status: 400,
      statusText: response.message,
      details: response.details,
    };
  }

  if (response instanceof ConflictError) {
    return {
      ok: false,
      status: 409,
      statusText: response.message,
    };
  }

  if (response instanceof Error) {
    // Re-throw any errors we don't handle specifically
    throw response;
  }

  if (isResponse(response)) {
    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
    };
  }

  return response;
};
