import { DeleteOutlined, EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Role } from '@interfaces/role';
import type { User } from '@interfaces/user';
import useEditableForm from '@pages/Management/hooks/use-editable-form';
import { stripAtSign } from '@pages/Management/UserManagement/strip-at-sign';
import type { LoaderData } from '@services/types/loader-data';
import { Button, Form, Popconfirm, Space, Table } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import EditableCell from '../EditableCell';
import type { EditableCellProps } from '../EditableCell/EditableCell';
import type { loader } from './loader';
import UserCreateForm from './UserCreateForm';
import styles from './UserManagement.module.scss';

interface UserForm extends Record<string, unknown> {
  username: string;
  full_name: string;
  password: string;
  role: Role;
  tg_username: string | null;
}

const UserManagement: FC = () => {
  const users = useLoaderData() as LoaderData<typeof loader>;

  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const { form, isLoadingOrSubmitting, isRecordEdited, cancelEditing, setRecordEditable, submitForm, resetFetcher } =
    useEditableForm<User, UserForm>(
      'id',
      ['username', 'full_name', 'role', 'tg_username', 'password'],
      'users-fetcher',
      true,
    );

  return (
    <>
      <Button
        className={styles.addBtn}
        type="primary"
        onClick={() => setAddModalOpen(true)}
        disabled={isLoadingOrSubmitting}
      >
        Add User
      </Button>
      <UserCreateForm
        open={isAddModalOpen}
        onCreate={() => setAddModalOpen(false)}
        onCancel={() => setAddModalOpen(false)}
      />
      <Form form={form} component={false}>
        <Table<User>
          dataSource={users}
          rowKey="id"
          loading={isLoadingOrSubmitting}
          rowClassName="editable-row"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        >
          <Table.Column<User> key="id" title="ID" dataIndex="id" />
          <Table.Column<User>
            key="username"
            title="Username"
            dataIndex="username"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'username',
              inputType: 'text',
              title: 'Username',
              editing: isRecordEdited(record),
              max: 32,
            })}
          />
          <Table.Column<User>
            key="full_name"
            title="Full Name"
            dataIndex="full_name"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'full_name',
              inputType: 'text',
              title: 'Full Name',
              editing: isRecordEdited(record),
              max: 64,
            })}
          />
          <Table.Column<User>
            key="password"
            title="Password"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'password',
              inputType: 'password',
              title: 'Password',
              placeholder: 'New password (optional)',
              editing: isRecordEdited(record),
              customPasswordInputProps: {
                showStrengthMeter: true,
                validatePasswordLevel: 4,
              },
            })}
          />
          <Table.Column<User>
            key="role"
            title="Role"
            dataIndex="role"
            render={(role: Role) => Object.keys(Role)[Object.values(Role).indexOf(role)]}
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'role',
              inputType: 'select',
              selectOptions: Object.entries(Role).map(([key, value]) => ({
                label: key,
                value,
              })),
              title: 'Role',
              editing: isRecordEdited(record),
            })}
          />

          <Table.Column<User>
            key="tg_username"
            title="TG Username"
            dataIndex="tg_username"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'tg_username',
              inputType: 'text',
              title: 'TG Username',
              editing: isRecordEdited(record),
              disabled: form.getFieldValue('role') !== 'admin',
              required: false,
              customRules: [
                {
                  type: 'string',
                  pattern: /^[a-z0-9_]{5,32}$/,
                  message: 'Username must be at least five characters long and can contain a-z, 0–9, and underscores',
                },
              ],
              inputProps: {
                onPaste: (e) => {
                  e.preventDefault();
                  form.setFieldsValue({ tg_username: stripAtSign(e.clipboardData.getData('text')) });
                },
              },
            })}
          />
          <Table.Column<User>
            key="action"
            title="Actions"
            render={(_, record) => {
              const editable = isRecordEdited(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button type="primary" icon={<SaveOutlined />} title="Save" onClick={() => submitForm(record)} />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={() => {
                          resetFetcher();
                          cancelEditing();
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Button type="default" icon={<EditOutlined />} onClick={() => setRecordEditable(record)} />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => submitForm({ id: record.id }, 'delete', undefined, 'DELETE')}
                      >
                        <Button danger icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </>
  );
};

export default UserManagement;
