import type { AuthContextType } from '@context/auth.context';
import type { OrganizationSettings } from '@interfaces/organization-settings';
import { actionRequest } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  Update = 'update-organization',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.Update:
        return await actionRequest<OrganizationSettings>(auth, request, 'manage/organization/settings', data);
      default:
        throw new Error(`Undefined intent '${intent}' for the action`);
    }
  }) satisfies ActionFunction;
