import type { TelegramMenuItemTranslation } from '@interfaces/telegram-menu-item-translation';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import { ACTIONS } from '@pages/Management/MenuManagement/action';
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface Values extends Record<string, unknown> {
  label: string;
  answer: string;
  language_code: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (translation: TelegramMenuItemTranslation) => void;
  onCancel: () => void;
  item_id: number;
  languages: Record<string, string>;
}

const MenuItemAddTranslationForm: FC<OwnProps> = ({ open, onCreate, onCancel, item_id, languages }) => {
  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
    resetFetcher,
  } = useForm<TelegramMenuItemTranslation, Values>(
    'menu-item-translation-add-form',
    true,
    (response) => response && onCreate(response),
  );

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    resetFetcher();
    onCancel();
  }, [form, resetFetcher, onCancel]);

  return (
    <Modal
      open={open}
      title="Add Menu Item Translation"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" disabled={isLoadingOrSubmitting} onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingOrSubmitting} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() =>
          submitForm(ACTIONS.AddMenuItemTranslation, 'POST', undefined, {
            item_id,
          })
        }
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={isLoadingOrSubmitting}
        initialValues={{ language_code: Object.keys(languages)[0] }}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="label"
          label="Label"
          rules={[
            {
              required: true,
              message: 'Please enter a label.',
            },
            {
              max: 64,
              message: 'Label can not exceed 64 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 64 }} />
        </Form.Item>
        <Form.Item name="answer" label="Telegram message">
          <Input />
        </Form.Item>
        <Form.Item
          name="language_code"
          label="Language"
          rules={[
            {
              required: true,
              message: 'Please select a language.',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={Object.entries(languages).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MenuItemAddTranslationForm;
