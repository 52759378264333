import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { languages } from '@interfaces/language';
import type { MessageTemplate, MessageTemplateTranslation } from '@interfaces/message-template';
import EditableCell from '@pages/Management/EditableCell';
import type { EditableCellProps } from '@pages/Management/EditableCell/EditableCell';
import useEditableForm from '@pages/Management/hooks/use-editable-form';
import { ACTIONS } from '@pages/Management/MessageTemplateManagement/action';
import TemplateAddForm from '@pages/Management/MessageTemplateManagement/TemplateAddForm';
import TemplateAddTranslationForm from '@pages/Management/MessageTemplateManagement/TemplateAddTranslationForm';
import type { LoaderData } from '@services/types/loader-data';
import { Alert, Button, Form, Popconfirm, Space, Table } from 'antd';
import type { ExpandedRowRender } from 'rc-table/lib/interface';
import type { FC, Key } from 'react';
import { useCallback, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import withStopPropagation from '../utils/ui/with-stop-propagation';
import type { loader } from './loader';
import styles from './MessageTemplateManagement.module.scss';

interface TemplateForm extends Record<string, unknown> {
  slug: string;
}

interface TemplateTranslationForm extends Record<string, unknown> {
  template: string;
  language_code: string;
}

const MessageTemplateManagement: FC = () => {
  const loaderData = useLoaderData() as LoaderData<typeof loader>;

  const {
    form: messageTemplateForm,
    isLoadingOrSubmitting: isLoadingOrSubmittingMessageTemplate,
    isRecordEdited: isMessageTemplateEdited,
    cancelEditing: cancelMessageTemplateEditing,
    setRecordEditable: setMessageTemplateEditable,
    submitForm: submitMessageTemplateForm,
    error: messageTemplateFormError,
    resetFetcher: resetMessageTemplateFetcher,
  } = useEditableForm<MessageTemplate, TemplateForm>('id', ['slug'], 'message-template-fetcher', true);
  const {
    form: messageTemplateTranslationForm,
    isLoadingOrSubmitting: isLoadingOrSubmittingMessageTemplateTranslation,
    isRecordEdited: isMessageTemplateTranslationEdited,
    cancelEditing: cancelMessageTemplateTranslationEditing,
    setRecordEditable: setMessageTemplateTranslationEditable,
    submitForm: submitMessageTemplateTranslationForm,
    error: messageTemplateTranslationFormError,
    resetFetcher: resetMessageTemplateTranslationFetcher,
  } = useEditableForm<MessageTemplateTranslation, TemplateTranslationForm>(
    ['template_id', 'language_code'],
    ['template', 'language_code'],
    'message-template-translation-fetcher',
    true,
  );

  const isLoadingOrSubmitting = isLoadingOrSubmittingMessageTemplate || isLoadingOrSubmittingMessageTemplateTranslation;

  const [isTemplateAddModalOpen, setTemplateAddModalOpen] = useState(false);
  const [isTemplateTranslationAddModalOpen, setTemplateTranslationAddModalOpen] = useState(false);
  const [addTemplateTranslationId, setAddTemplateTranslationId] = useState(0);
  const [addTemplateTranslationLanguages, setAddTemplateTranslationLanguages] = useState(languages);
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);

  const onTriggerCreateTemplate = useCallback((template: Omit<MessageTemplate, 'translations'>) => {
    setExpandedRowKeys([template.id as number]);
    setTemplateAddModalOpen(false);
  }, []);

  const onTriggerCreateTemplateTranslation = useCallback((translation: MessageTemplateTranslation) => {
    setExpandedRowKeys([translation.template_id]);
    setTemplateTranslationAddModalOpen(false);
  }, []);

  const expandedRowRender: ExpandedRowRender<MessageTemplate> = (record) => {
    const translations = record.translations;
    const existingLanguages = translations.map((translation) => translation.language_code);

    return (
      <Form form={messageTemplateTranslationForm} component={false}>
        <Table<MessageTemplateTranslation>
          dataSource={translations}
          rowKey={(record) => `${record.template_id}_${record.language_code}`}
          pagination={false}
          rowClassName="editable-row"
          loading={isLoadingOrSubmitting}
          components={{
            body: { cell: EditableCell },
          }}
        >
          <Table.Column<MessageTemplateTranslation>
            key="template"
            dataIndex="template"
            title="Text"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'template',
              inputType: 'textarea',
              title: 'Text',
              editing: isMessageTemplateTranslationEdited(record),
              required: true,
              customRules: [
                {
                  pattern: /\S/,
                  message: 'At least one character other than space is required.',
                },
              ],
            })}
          />
          <Table.Column<MessageTemplateTranslation>
            key="language_code"
            dataIndex="language_code"
            title="Language"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'language_code',
              inputType: 'select',
              selectOptions: Object.entries(languages)
                .filter(([key]) => key === record.language_code || !existingLanguages.includes(key))
                .map(([key, value]) => ({
                  value: key,
                  label: value,
                })),
              title: 'Language',
              editing: record.language_code !== 'en' && isMessageTemplateTranslationEdited(record),
              required: true,
            })}
            render={(value: string) => {
              return value in languages ? languages[value] : value;
            }}
          />
          <Table.Column<MessageTemplateTranslation>
            key="actions"
            title="Actions"
            render={(_, record) => {
              const editable = isMessageTemplateTranslationEdited(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        title="Save"
                        onClick={() =>
                          submitMessageTemplateTranslationForm(record, ACTIONS.UpdateTemplateTranslation, {
                            language_code_previous: record.language_code,
                          })
                        }
                      />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={() => {
                          resetMessageTemplateTranslationFetcher();
                          cancelMessageTemplateTranslationEditing();
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setMessageTemplateTranslationEditable(record);
                        }}
                      />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => {
                          // noinspection JSIgnoredPromiseFromCall
                          submitMessageTemplateTranslationForm(
                            {
                              template_id: record.template_id,
                              language_code: `${record.language_code}`,
                            },
                            ACTIONS.DeleteTranslation,
                            undefined,
                            'DELETE',
                          );
                        }}
                        disabled={record.language_code === 'en'}
                      >
                        <Button danger icon={<DeleteOutlined />} disabled={record.language_code === 'en'} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    );
  };

  return (
    <>
      <TemplateAddForm
        open={isTemplateAddModalOpen}
        onCreate={onTriggerCreateTemplate}
        onCancel={() => {
          setTemplateAddModalOpen(false);
        }}
      />
      <TemplateAddTranslationForm
        open={isTemplateTranslationAddModalOpen}
        onCreate={onTriggerCreateTemplateTranslation}
        onCancel={() => {
          setTemplateTranslationAddModalOpen(false);
        }}
        template_id={addTemplateTranslationId}
        languages={addTemplateTranslationLanguages}
      />
      <div className={styles.buttons}>
        <Button
          type="primary"
          onClick={() => {
            setTemplateAddModalOpen(true);
          }}
          disabled={isLoadingOrSubmitting}
        >
          Add Shortcut
        </Button>
      </div>
      <Form form={messageTemplateForm} component={false}>
        {(messageTemplateFormError || messageTemplateTranslationFormError) && (
          <Alert
            message={messageTemplateFormError || messageTemplateTranslationFormError}
            style={{ margin: '24px 0' }}
            type="error"
          />
        )}
        <Table<MessageTemplate>
          dataSource={loaderData}
          rowKey="id"
          loading={isLoadingOrSubmitting}
          rowClassName="editable-row"
          components={{
            body: { cell: EditableCell },
          }}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: [],
            expandedRowKeys,
            onExpandedRowsChange: (expandedKeys) => {
              setExpandedRowKeys(expandedKeys);
            },
            expandRowByClick: true,
          }}
          pagination={false}
        >
          <Table.Column<MessageTemplate> key="id" title="ID" dataIndex="id" />
          <Table.Column<MessageTemplate>
            key="slug"
            title="Shortcut"
            dataIndex="slug"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'slug',
              inputType: 'text',
              title: 'Shortcut',
              editing: isMessageTemplateEdited(record),
              required: true,
              max: 128,
              customRules: [
                {
                  pattern: /^[\w-]+$/,
                  message: 'The shortcut can only contain Latin letters, numbers, dashes and underscores.',
                },
              ],
            })}
          />
          <Table.Column<MessageTemplate>
            key="actions"
            title="Actions"
            render={(_, record) => {
              const editable = isMessageTemplateEdited(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        title="Save"
                        onClick={withStopPropagation(() => submitMessageTemplateForm(record, ACTIONS.UpdateTemplate))}
                      />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={withStopPropagation(() => {
                          resetMessageTemplateFetcher();
                          cancelMessageTemplateEditing();
                        })}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        title="Add Translation"
                        onClick={withStopPropagation(() => {
                          setAddTemplateTranslationId(record.id);
                          setAddTemplateTranslationLanguages(
                            Object.fromEntries(
                              Object.entries(languages).filter(
                                ([key]) =>
                                  !record.translations.map((translation) => translation.language_code).includes(key),
                              ),
                            ),
                          );
                          setTemplateTranslationAddModalOpen(true);
                        })}
                      />
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        title="Edit"
                        onClick={withStopPropagation(() => setMessageTemplateEditable(record))}
                      />
                      <Popconfirm
                        title="Sure to delete template with all of it's translations?"
                        onConfirm={withStopPropagation(() =>
                          submitMessageTemplateForm({ id: record.id }, ACTIONS.DeleteTemplate, undefined, 'DELETE'),
                        )}
                        onCancel={withStopPropagation()}
                        onPopupClick={withStopPropagation()}
                      >
                        <Button danger title="Delete" icon={<DeleteOutlined />} onClick={withStopPropagation()} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </>
  );
};

export default MessageTemplateManagement;
