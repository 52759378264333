import type { MessageTemplate } from '@interfaces/message-template';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import { ACTIONS } from '@pages/Management/MessageTemplateManagement/action';
import { Alert, Button, Form, Input, Modal } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface TemplateWithDefaultTranslation extends Omit<MessageTemplate, 'translations'> {
  template: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (template: Omit<MessageTemplate, 'translations'>) => void;
  onCancel: () => void;
}

const TemplateAddForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
    resetFetcher,
  } = useForm<Omit<MessageTemplate, 'translations'>, TemplateWithDefaultTranslation>(
    'message-template-add-form',
    true,
    (response) => response && onCreate(response),
  );

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    resetFetcher();
    onCancel();
  }, [form, resetFetcher, onCancel]);

  return (
    <Modal
      open={open}
      title="Add template"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" disabled={isLoadingOrSubmitting} onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingOrSubmitting} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => submitForm(ACTIONS.AddTemplate, 'POST')}
        disabled={isLoadingOrSubmitting}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="slug"
          label="Shortcut"
          rules={[
            {
              required: true,
              message: 'Please enter a shortcut.',
            },
            {
              max: 128,
              message: 'The shortcut can not exceed 128 characters.',
            },
            {
              pattern: /^[\w-]+$/,
              message: 'The shortcut can only contain Latin letters, numbers, dashes and underscores.',
            },
          ]}
        >
          <Input count={{ show: true, max: 128 }} />
        </Form.Item>
        <Form.Item
          name="template"
          label="Text (English)"
          rules={[
            {
              required: true,
              message: 'Please enter a text for this template.',
            },
            {
              pattern: /\S/,
              message: 'At least one character other than space is required.',
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TemplateAddForm;
