import { languages } from '@interfaces/language';
import type { TelegramGroup } from '@interfaces/telegram-group';
import { ACTIONS } from '@pages/Management/ConversationManagement/action';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface Values extends Record<string, unknown> {
  id: number;
  name: string;
  language_code: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (group: TelegramGroup) => void;
  onCancel: () => void;
}

const CreateConversationForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
    resetFetcher,
  } = useForm<TelegramGroup, Values>('conversation-add-form', true, (response) => response && onCreate(response));

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    resetFetcher();
    onCancel();
  }, [form, resetFetcher, onCancel]);

  return (
    <Modal
      open={open}
      title="Create a new conversation"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" disabled={isLoadingOrSubmitting} onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingOrSubmitting} onClick={form.submit}>
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => submitForm(ACTIONS.Authorize, 'POST')}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={isLoadingOrSubmitting}
        initialValues={{ language_code: 'en' }}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="id"
          label="Telegram group ID"
          rules={[
            {
              required: true,
              message: 'Please enter an id.',
            },
            {
              type: 'string',
              pattern: /^-[1-9][0-9]*$/,
              message: 'ID of the group must be a negative number',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Conversation name"
          rules={[
            {
              required: true,
              message: 'Please enter a name.',
            },
            {
              max: 96,
              message: 'Name can not exceed 96 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 96 }} />
        </Form.Item>
        <Form.Item
          name="language_code"
          label="Default language"
          rules={[
            {
              required: true,
              message: 'Please select the default language for the conversation',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={Object.entries(languages).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateConversationForm;
