import type { MessageTemplateTranslation } from '@interfaces/message-template';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import { ACTIONS } from '@pages/Management/MessageTemplateManagement/action';
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface OwnProps {
  open: boolean;
  onCreate: (translation: MessageTemplateTranslation) => void;
  onCancel: () => void;
  template_id: number;
  languages: Record<string, string>;
}

const TemplateAddTranslationForm: FC<OwnProps> = ({ open, onCreate, onCancel, template_id, languages }) => {
  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
    resetFetcher,
  } = useForm<MessageTemplateTranslation, Omit<MessageTemplateTranslation, 'template_id'>>(
    'message-template-translation-add-form',
    true,
    (response) => response && onCreate(response),
  );

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    resetFetcher();
    onCancel();
  }, [form, resetFetcher, onCancel]);

  return (
    <Modal
      open={open}
      title="Add Template Translation"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" disabled={isLoadingOrSubmitting} onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingOrSubmitting} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() =>
          submitForm(ACTIONS.AddTemplateTranslation, 'POST', undefined, {
            template_id,
          })
        }
        disabled={isLoadingOrSubmitting}
        initialValues={{ language_code: Object.keys(languages)[0] }}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="template"
          label="Text"
          rules={[
            {
              required: true,
              message: 'Please enter a text for this translation.',
            },
            {
              pattern: /\S/,
              message: 'At least one character other than space is required.',
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="language_code"
          label="Language"
          rules={[
            {
              required: true,
              message: 'Please select a language.',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={Object.entries(languages).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TemplateAddTranslationForm;
