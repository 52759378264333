import type { AuthContextType } from '@context/auth.context';
import type { User } from '@interfaces/user';
import { actionRequest } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  Add = 'add',
  Update = 'update',
  Delete = 'delete',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.Add:
        return await actionRequest<User>(auth, request, 'manage/user', data);
      case ACTIONS.Update:
      case ACTIONS.Delete: {
        const { id: userId, ...rest } = data;
        return await actionRequest<User>(
          auth,
          request,
          `manage/user/${userId}`,
          intent === ACTIONS.Update ? rest : undefined,
        );
      }
      default:
        throw new Error(`Undefined intent '${intent}' for the action`);
    }
  }) satisfies ActionFunction;
