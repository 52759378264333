import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import type { ColumnsType, PaginatedFilteredTableRef } from '@components/PaginatedFilteredTable';
import PaginatedFilteredTable from '@components/PaginatedFilteredTable';
import type { TelegramUser } from '@interfaces/telegram-user';
import type { EditableCellProps } from '@pages/Management/EditableCell/EditableCell';
import EditableCell from '@pages/Management/EditableCell/EditableCell';
import useEditableForm from '@pages/Management/hooks/use-editable-form';
import type { LoaderData } from '@services/types/loader-data';
import { Button, Form, Space, Tag } from 'antd';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { getFormattedNumber } from 'react-phone-hooks';
import { useLoaderData } from 'react-router-dom';

import type { loader } from './loader';

interface UserForm extends Record<string, unknown> {
  full_name: string;
  phone_number: string | null;
}

const TelegramUserManagement: FC = () => {
  const loaderData = useLoaderData() as LoaderData<typeof loader>;
  const [data, setData] = useState(loaderData);
  useEffect(() => {
    setData(loaderData);
  }, [loaderData]);

  const tableRef = useRef<PaginatedFilteredTableRef>(null);
  const { form, isLoadingOrSubmitting, isRecordEdited, cancelEditing, setRecordEditable, submitForm, resetFetcher } =
    useEditableForm<TelegramUser, UserForm>('id', ['full_name', 'phone_number'], 'tg-users-fetcher', true);

  const columns: ColumnsType<TelegramUser> = [
    {
      key: 'full_name',
      title: 'Full Name',
      dataIndex: 'full_name',
      filterable: {
        placeholder: 'full name',
      },
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'full_name',
        inputType: 'text',
        title: 'Full Name',
        editing: isRecordEdited(record),
        style: { minWidth: '170px' },
      }),
    },
    {
      key: 'telegram_groups.name',
      title: 'Conversation Name(s)',
      dataIndex: 'conversations',
      filterable: {
        placeholder: 'conversation name',
      },
      render: (value: string[] | null) =>
        value ? (
          <>
            {value.map((name, index) => (
              <Tag key={index}>{name}</Tag>
            ))}
          </>
        ) : null,
    },
    {
      key: 'phone_number',
      title: 'Phone Number',
      dataIndex: 'phone_number',
      filterable: {
        placeholder: 'phone number',
      },
      width: 230,
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'phone_number',
        inputType: 'phone-number',
        title: 'Phone Number',
        required: false,
        placeholder: '+1 (999) 999-9999',
        editing: isRecordEdited(record),
      }),
      render: (value: string | null) => (value ? getFormattedNumber(value.replace(/^\+/, '')) : null),
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 80,
      render: (_, record) => {
        const editable = isRecordEdited(record);
        return (
          <Space size="middle">
            {editable ? (
              <>
                <Button type="primary" icon={<SaveOutlined />} title="Save" onClick={() => submitForm(record)} />
                <Button
                  type="default"
                  icon={<StopOutlined />}
                  title="Cancel"
                  onClick={() => {
                    resetFetcher();
                    cancelEditing();
                  }}
                />
              </>
            ) : (
              <>
                <Button type="default" icon={<EditOutlined />} onClick={() => setRecordEditable(record)} />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <PaginatedFilteredTable<TelegramUser>
        ref={tableRef}
        data={data}
        columns={columns}
        rowKey="id"
        loading={isLoadingOrSubmitting}
        rowClassName="editable-row"
        components={{ body: { cell: EditableCell } }}
      />
    </Form>
  );
};

export default TelegramUserManagement;
