import type { AuthContextType } from '@context/auth.context';
import type { TelegramMenuItem } from '@interfaces/telegram-menu-item';
import type { TelegramMenuItemTranslation } from '@interfaces/telegram-menu-item-translation';
import { actionRequest } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  AddMenuItem = 'add-menu-item',
  UpdateMenuItem = 'update-menu-item',
  DeleteMenuItem = 'delete-menu-item',
  AddMenuItemTranslation = 'add-menu-item-translation',
  UpdateMenuItemTranslation = 'update-translation',
  DeleteMenuItemTranslation = 'delete-translation',
  SwapOrder = 'swap-sort-order',
  Notify = 'notify-telegram',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.AddMenuItem:
        return await actionRequest<TelegramMenuItem>(auth, request, 'manage/telegram-menu', data);
      case ACTIONS.UpdateMenuItem:
      case ACTIONS.DeleteMenuItem: {
        const { id: itemId, ...rest } = data;
        return await actionRequest<TelegramMenuItem>(
          auth,
          request,
          `manage/telegram-menu/${itemId}`,
          intent === ACTIONS.UpdateMenuItem ? rest : undefined,
        );
      }
      case ACTIONS.AddMenuItemTranslation: {
        const { item_id: itemId, ...rest } = data;
        return await actionRequest<TelegramMenuItemTranslation>(
          auth,
          request,
          `manage/telegram-menu/${itemId}/translation`,
          rest,
        );
      }
      case ACTIONS.UpdateMenuItemTranslation: {
        const { item_id: itemId, language_code_previous, ...rest } = data;
        return await actionRequest<TelegramMenuItemTranslation>(
          auth,
          request,
          `manage/telegram-menu/${itemId}/translation/${language_code_previous}`,
          rest,
        );
      }
      case ACTIONS.DeleteMenuItemTranslation: {
        const { item_id: itemId, language_code } = data;
        return await actionRequest<TelegramMenuItemTranslation>(
          auth,
          request,
          `manage/telegram-menu/${itemId}/translation/${language_code}`,
        );
      }
      case ACTIONS.SwapOrder: {
        const { src_id, ...rest } = data;
        return await actionRequest<Response>(auth, request, `manage/telegram-menu/${src_id}/swap-order`, rest);
      }
      case ACTIONS.Notify:
        return await actionRequest<Response>(auth, request, `manage/telegram/keyboard-change`);
      default:
        throw new Error(`Undefined intent '${intent}' for the action`);
    }
  }) satisfies ActionFunction;
