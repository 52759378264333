import PasswordInputWithStrengthMeter from '@components/PasswordInputWithStrengthMeter';
import { Role } from '@interfaces/role';
import type { User } from '@interfaces/user';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import { stripAtSign } from '@pages/Management/UserManagement/strip-at-sign';
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface Values extends Record<string, unknown> {
  username: string;
  full_name: string;
  password: string;
  role: Role;
  tg_username: string | null;
}

interface OwnProps {
  open: boolean;
  onCreate: (user: User) => void;
  onCancel: () => void;
}

const UserCreateForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
    resetFetcher,
  } = useForm<User, Values>('user-add-form', true, (response) => response && onCreate(response));

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    resetFetcher();
    onCancel();
  }, [form, resetFetcher, onCancel]);

  return (
    <Modal
      open={open}
      title="Add new user"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" disabled={isLoadingOrSubmitting} onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingOrSubmitting} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => submitForm('add', 'POST')}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={isLoadingOrSubmitting}
        initialValues={{ role: 'agent' }}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="full_name"
          label="Full Name"
          rules={[
            {
              required: true,
              message: 'Please enter a full name.',
            },
            {
              max: 64,
              message: 'Full name can not exceed 64 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 64 }} autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: 'Please enter a username.',
            },
            {
              max: 32,
              message: 'Username can not exceed 32 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 32 }} autoComplete="off" />
        </Form.Item>
        <PasswordInputWithStrengthMeter
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please enter a password.',
            },
          ]}
          inputProps={{
            showStrengthMeter: true,
            validatePasswordLevel: 4,
            autoComplete: 'new-password',
          }}
        />
        <Form.Item
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: 'Please select a role.',
            },
          ]}
        >
          <Select
            options={Object.entries(Role).map(([key, value]) => ({
              label: key,
              value,
            }))}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
          {({ getFieldValue }) => (
            <Form.Item
              name="tg_username"
              label="TG Username"
              rules={[
                {
                  type: 'string',
                  pattern: /^[a-z0-9_]{5,32}$/,
                  message: 'Username must be at least five characters long and can contain a-z, 0–9, and underscores',
                },
              ]}
            >
              <Input
                disabled={getFieldValue('role') !== Role.Admin}
                count={{ show: true, max: 32 }}
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                  form.setFieldsValue({ tg_username: stripAtSign(e.clipboardData.getData('text')) });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserCreateForm;
