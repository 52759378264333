import { useFetcherWithReset } from '@pages/Management/hooks/use-fetcher-with-reset';
import { isValidationErrorResponse } from '@services/types/validation-error-response';
import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import type { Fetcher, V7_FormMethod } from 'react-router-dom';

type JsonObject = {
  [Key in string]: JsonPrimitive;
} & {
  [Key in string]?: JsonPrimitive | undefined;
};
type JsonPrimitive = string | number | boolean | null;

type Destructor = () => void;

function isFetcherLoading(state: Fetcher['state']) {
  return state !== 'idle';
}

export const mapEmptyToNull = ([key, value]: [string, unknown]) => [key, value === '' ? null : value];

const isErrorResponse = <T extends object>(response: Response | T): response is Response => {
  return 'ok' in response && !response.ok;
};

export function useForm<T extends Record<string, unknown>, F extends Partial<T>>(
  fetcherKey?: string,
  resetAfterSubmit?: boolean,
  onSubmit?: (response?: T, error?: Response) => Destructor | void,
) {
  const [form] = Form.useForm<F>();
  const [error, setError] = useState<string>();
  const fetcher = useFetcherWithReset<Response | T>({ key: fetcherKey });
  const { submit, state, data: response, reset: resetFetcher } = fetcher;
  const isLoadingOrSubmitting = isFetcherLoading(state);

  const submitForm = useCallback(
    async (intent: string, method: V7_FormMethod, initialValues?: Partial<T>, additionalParams?: JsonObject) => {
      const values = Object.entries(await form.validateFields({ dirty: true }));
      const transformedValues = initialValues
        ? values.filter(([key, value]) => initialValues[key] !== value && value !== undefined).map(mapEmptyToNull)
        : values.map(mapEmptyToNull);

      if (transformedValues.length === 0 && additionalParams === undefined) {
        return;
      }

      resetFetcher();

      submit(
        {
          intent,
          ...Object.fromEntries(transformedValues),
          ...(additionalParams ?? {}),
        },
        {
          method,
          encType: 'application/json',
        },
      );
    },
    [form, resetFetcher, submit],
  );

  useEffect(() => {
    // before any submit
    if (response === undefined) {
      return;
    }

    if (isValidationErrorResponse(response)) {
      const fieldErrors: { name: string; errors: string[] }[] = [];
      const formErrors: string[] = [];
      for (const key in response.details) {
        if (form.getFieldInstance(key) !== undefined) {
          fieldErrors.push({
            name: key,
            errors: response.details[key],
          });
        } else {
          formErrors.push(response.details[key].join(', '));
        }
      }

      form.setFields(fieldErrors as Parameters<typeof form.setFields>[0]);
      setError(formErrors.join(', '));

      return onSubmit?.(undefined, response);
    }

    if (isErrorResponse(response)) {
      setError(response.statusText);

      return onSubmit?.(undefined, response);
    }

    setError(undefined);

    if (resetAfterSubmit) {
      resetFetcher();
      form.resetFields();
    }

    return onSubmit?.(response);
  }, [form, onSubmit, resetAfterSubmit, response, resetFetcher]);

  return {
    form,
    error,
    isLoadingOrSubmitting,
    submitForm,
    submitFunction: submit,
    resetFetcher,
  };
}

function useEditableForm<T extends Record<string, unknown>, F extends Partial<T>>(
  recordKey: keyof T | (keyof T)[],
  editableKeys: (keyof T)[],
  fetcherKey?: string,
  resetAfterSubmit?: boolean,
  onSubmit?: (response?: T, error?: Response) => Destructor | void,
) {
  const [editingKey, setEditingKey] = useState<unknown>();
  const onInternalSubmit = useCallback(
    (response?: T, error?: Response) => {
      if (!error) {
        setEditingKey(undefined);
      }

      return onSubmit?.(response);
    },
    [onSubmit],
  );
  const {
    form,
    isLoadingOrSubmitting,
    submitForm: submitFormInternal,
    error,
    submitFunction,
    resetFetcher,
  } = useForm<T, F>(fetcherKey, resetAfterSubmit, onInternalSubmit);

  const setRecordEditable = useCallback(
    (record: T) => {
      if (Array.isArray(recordKey)) {
        setEditingKey(recordKey.map((key) => record[key]).join('_'));
      } else {
        setEditingKey(record[recordKey]);
      }
      const values = editableKeys.reduce(
        (acc, key) => {
          // eslint-disable-next-line
          // @ts-ignore
          acc[key] = record[key];
          return acc;
        },
        {} as Parameters<typeof form.setFieldsValue>[0],
      );
      form.setFieldsValue(values);
    },
    [editableKeys, form, recordKey],
  );

  const isRecordEdited = (record: T) => {
    if (Array.isArray(recordKey)) {
      return recordKey.map((key) => record[key]).join('_') === editingKey;
    }

    return record[recordKey] === editingKey;
  };
  const cancelEditing = () => {
    setEditingKey(undefined);
  };

  const submitForm = useCallback(
    (record?: Partial<T>, intent?: string, additionalParams?: JsonObject, method?: V7_FormMethod) => {
      const params = record
        ? {
            ...(Array.isArray(recordKey)
              ? recordKey.reduce((acc, key) => {
                  acc[key as string] = record[key] as JsonPrimitive;
                  return acc;
                }, {} as JsonObject)
              : { [recordKey]: record[recordKey] as JsonPrimitive }),
          }
        : {};
      return submitFormInternal(
        intent ?? 'update',
        method || 'PATCH',
        record,
        record ? { ...params, ...(additionalParams ?? {}) } : additionalParams ?? {},
      );
    },
    [submitFormInternal, recordKey],
  );

  return {
    form,
    isLoadingOrSubmitting,
    cancelEditing,
    isRecordEdited,
    setRecordEditable,
    submitForm,
    submitFunction,
    error,
    resetFetcher,
  };
}

export default useEditableForm;
